import React from 'react';
import { css, keyframes } from 'react-emotion';

/**
 * Original code https://codepen.io/tari/pen/pgjxbe
 */
export default () => (
  <div className={style2}>
  </div>
);

const center = css`
  display: flex;
  justify-content: center;
  align-content: center;
`;



const clouds = keyframes`
\t 100% {
\t\t background-position: 300px 0;
\t}
`;

const tree = keyframes`
50% {
\t\t background-position: 179.6407185629px 0, 179.6407185629px 0, 31.25px 0, 200px 0, 200px 0, 51.724137931px 0;
\t}
`;


const style2 = css`
  width: 300px;
  height: 300px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -150px;
  margin-left: -150px;
  border-radius: 50%;
  border: 5px solid #eee;
  background-color: #0677bd;
  animation: ${clouds} 25s linear infinite;
  background-position: 0 0;
  background-image: radial-gradient(
        circle at 8% 40%, 
        #fff 3%, 
        transparent 3.3%
        ), 
        radial-gradient(circle at 13% 37%, #fff 4%, transparent 4.3%),
        radial-gradient(circle at 15% 40%, #fff 5%, transparent 5.3%), 
        radial-gradient(circle at 20% 38%, #fff 4%, transparent 4.3%), 
        radial-gradient(circle at 32% 16%, #fff 3%, transparent 3.3%), 
        radial-gradient(circle at 36% 16%, #fff 4%, transparent 4.3%), 
        radial-gradient(circle at 41% 16%, #fff 3%, transparent 3.3%), 
        radial-gradient(circle at 78% 7%, #fff 4%, transparent 4.3%), 
        radial-gradient(circle at 83% 10%, #fff 4%, transparent 4.3%), 
        radial-gradient(circle at 72% 8%, #fff 3%, transparent 3.3%), 
        radial-gradient(circle at 76% 12%, #fff 3%, transparent 3.3%), 
        radial-gradient(circle at 82% 47%, #fff 4%, transparent 4.3%), 
        radial-gradient(circle at 86% 45%, #fff 3%, transparent 3.3%), 
        radial-gradient(circle at 88% 48%, #fff 3%, transparent 3.3%), 
        radial-gradient(circle at 91% 46%, #fff 3%, transparent 3.3%);
        
     &:before, &:after{
        content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
     }
     
     &:after {
          animation: ${tree} 2s linear infinite;
  background-image: linear-gradient(80deg, #365652 0.75%, transparent 1.05%), linear-gradient(-80deg, #365652 0.75%, transparent 1.05%), radial-gradient(circle at 50% 95%, #27b774 2.1%, transparent 2.4%), linear-gradient(80deg, #365652 1.5%, transparent 1.8%), linear-gradient(-80deg, #365652 1.5%, transparent 1.8%), radial-gradient(circle at 50% 90%, #27b774 4.2%, transparent 4.5%);
  background-position: 179.6407185629px 0, 179.6407185629px 0, 30px 0, 200px 0, 200px 0, 50px 0;
     }
     
     &:before {
        background-image: linear-gradient(63deg, #9ad7cc 20%, transparent 20.3%), linear-gradient(-63deg, #9ad7cc 20%, transparent 20.3%), linear-gradient(65deg, rgba(0, 0, 0, 0.15) 11.2%, transparent 11.5%), linear-gradient(-65deg, rgba(0, 0, 0, 0.15) 11.2%, transparent 11.5%), linear-gradient(63deg, #9ad7cc 13%, transparent 13.3%), linear-gradient(-63deg, #9ad7cc 13%, transparent 13.3%), linear-gradient(63deg, #79cabb 24%, transparent 24.3%), linear-gradient(-63deg, #79cabb 24%, transparent 24.3%);
  background-position: 240px 0, 240px 0, 197.3684210526px 0, 197.3684210526px 0, 50px 0, 50px 0, 142.8571428571px 0, 142.8571428571px 0;
     }

    
`;

