import React from 'react';
import { css, keyframes } from 'react-emotion';

import theme from '../../style/theme';

export default ({ children }) => {
    return (
        <div className={wrapperStyle}>
            <div className={snowflakesStyle} />
            {children}
        </div>
    );
};

const wrapperStyle = css`
  position: relative;
  overflow: hidden;
  background: ${theme.colors.easterYellow};
  color: ${theme.colors.knowitPink};
`;




let snowflakesStyle = css`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;
