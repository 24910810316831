import React from 'react';
import useDocumentTitle from '@rehooks/document-title';

import theme from './style/theme';
import Container from './components/Container';
import TextAlign from './components/TextAlign';
import EasterEggs from './components/EasterEggs';
import Heading from './components/Heading';
import media from './style/media';
import Button from "./components/Button";
import {Link} from "@reach/router";
import {css} from "react-emotion";
import ButtonFrontPage from "./components/ButtonFrontPage";

export default () => {
  useDocumentTitle('Påskenøtter');

  return (
    <TextAlign>
      <Container css={{ minHeight: '40vh' }}>
        <H1>
          Påskenøtter{' '}
          <small css={{ display: 'block', fontSize: '0.5em' }}>by knowit</small>
        </H1>
        <P>
          Løs nøttene og bli med i trekningen av en donasjon på 5000,- NOK til en valgfri sak på <a href="https://bidra.no/">bidra.no</a>
        </P>
        <P>En ny nøtt åpnes hver dag fra torsdag 09.04.20 til mandag 13.04.20</P>
      </Container>

      <EasterEggs>
        <Container css={{ padding: '120px 0', position: 'relative' }}>
          <Heading css={{color: '#C05CDC', fontWeight: 'bold'}} size="1" bold='true'>🐣 Påsken 2020 🐣</Heading>
          <P css={{color: '#333333', fontSize: '1.2rem'}}>Løs alle nøttene!</P>
            <Link to="/doors">
                <ButtonFrontPage css={{backgroundColor: "#C05CDC"}}>Bli med på kodeknekkingen!</ButtonFrontPage>
            </Link>
        </Container>
      </EasterEggs>
    </TextAlign>
  );
};

const H1 = props => (
  <h1
    css={`
      font-family: ${theme.fontFamilyCode};
      font-size: 60px;
      text-transform: uppercase;
      ${media.mobile`
        font-size: 42px;
      `};
    `}
    {...props}
  />
);

const P = props => <p css={{ fontSize: '1.2rem' }} {...props} />;
